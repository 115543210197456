// TODO: Replace once panel is available thorugh components library

import React from "react";

const Panel = ({ children, style, ...props }) => {
  return (
    <div
      style={{
        ...style,
        background: "#fff",
        borerRadius: "2px",
        border: "1px solid #D8D8D8",
        boxShadow: "0 1px 3px 0 rgba(0,0,0,0.1)",
        padding: "1.5rem"
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export default Panel;
