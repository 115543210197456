import moment from "moment";
/**
 * Generates a sequence of time period pairs defined by the maxChunkSize.
 * @param {number} start
 * @param {number} end
 * @param {number | string} maxChunkSize
 * @return {Generator<[number, number]>}
 */
export function* generateTimestampPeriods(start, end, maxChunkSize) {
  // Discrete implementation
  if (typeof maxChunkSize === "number") {
    for (let current = start; current < end; current += maxChunkSize) {
      yield [current, Math.min(end, current + maxChunkSize)];
    }
    return;
  }

  // Dynamic implementation
  if (typeof maxChunkSize === "string") {
    let current = start;
    while (current < end) {
      let next = moment.utc(current).add(1, maxChunkSize);

      if (!next.isValid()) {
        return;
      }

      yield [current, Math.min(end, next.valueOf())];

      current = next.valueOf();
    }
    return;
  }
}
