import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import Page from "./components/Page";
import Panel from "./components/Panel";
import { ReactComponent as Trophy } from "./components/Images/trophy.svg";

import useMetrics from "./hooks/useMetrics";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@sudokrew/wespac-components";

/**
 *
 * @param {number | null} number
 */
function formatNumberToString(number) {
  if (number === null) {
    return "-";
  }

  return number.toLocaleString();
}

/**
 *
 * @param {number | null} number
 * @param {label | null} string
 * @param {locales | undefined} string
 *
 */
function formatNumberToCurrency(number, label, locales = undefined) {
  if (number === null) {
    return "-";
  }

  return `${label}${number.toLocaleString(locales, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`;
}

export const SidebarMetricItem = props => {
  let { value, formatValue, suffix } = props;

  if (typeof formatValue === "function") {
    value = formatValue(value);
  }

  return (
    <div className="sidebar_metrics__container" style={props.style}>
      <div style={{ width: "100%" }}>
        <strong className="sidebar_metrics__value">
          {value}
          {suffix}
        </strong>
      </div>
      <span className="sidebar_metrics__label">{props.label}</span>
    </div>
  );
};

SidebarMetricItem.propTypes = {
  value: PropTypes.number,
  formatValue: PropTypes.func,
  label: PropTypes.string,
  suffix: PropTypes.string
};

SidebarMetricItem.defaultProps = {
  value: null,
  label: "",
  suffix: ""
};

export const SidebarAggregateFishRow = props => {
  let { imageSrc, commonName, poundsMTD } = props;

  return (
    <TableRow>
      <TableCell>{commonName}</TableCell>
      <TableCell style={{ textAlign: "left" }}>
        {formatNumberToString(poundsMTD)} lbs
      </TableCell>
    </TableRow>
  );
};

SidebarAggregateFishRow.propTypes = {
  poundsMTD: PropTypes.number,
  imgSrc: PropTypes.string,
  commonName: PropTypes.string
};

SidebarAggregateFishRow.defaultProps = {
  poundsMTD: 0,
  imgSrc: null,
  commonName: ""
};

export const MainAggregateFishTable = props => {
  let { aggregateFishDataBySpecies, headCells } = props;

  return (
    <Table>
      <TableHead>
        <TableRow style={{ backgroundColor: "#fff" }}>
          {headCells.map((cellHeading, i) => {
            return (
              <TableCell key={`heading-${i}`}>{cellHeading.label}</TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {aggregateFishDataBySpecies.map((fishData, j) => {
          return (
            <TableRow>
              <TableCell>{fishData.species.common_name}</TableCell>
              <TableCell style={{ textAlign: "left" }}>
                {formatNumberToString(fishData.year_sold_lbs)}
              </TableCell>
              <TableCell style={{ textAlign: "left" }}>
                {formatNumberToCurrency(
                  fishData.year_average_market_price_usd,
                  "$"
                )}
              </TableCell>
              <TableCell style={{ textAlign: "left" }}>
                {formatNumberToString(fishData.month_sold_lbs)}
              </TableCell>
              <TableCell style={{ textAlign: "left" }}>
                {formatNumberToCurrency(
                  fishData.month_average_market_price_usd,
                  "$"
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

MainAggregateFishTable.propTypes = {
  poundsMTD: PropTypes.number,
  commonName: PropTypes.string
};

MainAggregateFishTable.defaultProps = {};

/**
 * @typedef CatchMethodCount
 * @property {string} type
 * @property {string} totalPoundMass
 */

/**
 * @typedef MarketDashboardProps
 * @property {number} year
 * @property {number} after
 * @property {number} before
 * @property {CatchMethodCount[]} metrics
 */

/**
 * @param {MarketDashboardProps} props
 */
const MarketDashboard = props => {
  useMetrics({
    after: props.after.valueOf(),
    before: props.before.valueOf(),
    dispatch: props.dispatch
  });

  const { metrics } = props;

  const totalCatchCount = metrics.get("Total Pounds Caught");
  const totalSoldCount = metrics.get("Total Pounds Sold");
  const totalSalesCount = metrics.get("Total Sales Reported");

  const totalPercentSold =
    !!totalSoldCount && !!totalCatchCount
      ? ((totalSoldCount / totalCatchCount) * 100).toFixed(2)
      : "-";

  const aggregateFishDataBySpecies = metrics.get(
    "Aggregate Fish Data By Species"
  );

  const topFishCaughtByWeight = [...aggregateFishDataBySpecies]
    .sort((a, b) => b.year_sold_lbs - a.year_sold_lbs)
    .slice(0, 3);

  const totalRegisteredVendors = metrics.get("Total Reporting Vendors");

  const fisherLeaderboard = metrics.get("Fisher Leaderboard");
  const vendorLeaderboard = metrics.get("Vendor Leaderboard");
  return (
    <Page
      title="Market"
      id="market_dashboard"
      style={{
        gridTemplateRows: "min-content min-content 1fr"
      }}
    >
      <div
        className="catch_dashboard__sidebar"
        style={{
          gridColumn: "1 / span 3",
          gridRow: "1 / span 1"
        }}
      >
        <Panel>
          <SidebarMetricItem
            value={totalRegisteredVendors}
            formatValue={formatNumberToString}
            label={`Total Reporting Vendors`}
          />
          <SidebarMetricItem
            value={totalSalesCount}
            formatValue={formatNumberToString}
            label={`Total Sales Reported`}
          />
          <SidebarMetricItem
            value={totalCatchCount}
            formatValue={formatNumberToString}
            label={`Total Pounds Caught for ${new Date().getFullYear()}`}
          />
          <SidebarMetricItem
            value={totalSoldCount}
            formatValue={formatNumberToString}
            label={`Total Pounds Purchased To Date`}
          />
          <SidebarMetricItem
            value={totalPercentSold}
            formatValue={formatNumberToString}
            label={`Of Reported Fish Purchased`}
            suffix={"%"}
          />
        </Panel>
        <Panel>
          <h2 className="sidebar_metrics__heading">Most Reported Fish</h2>
          <Table>
            {topFishCaughtByWeight.map(speciesMetric => {
              return (
                <SidebarAggregateFishRow
                  commonName={speciesMetric.species.common_name}
                  poundsMTD={speciesMetric.year_sold_lbs}
                />
              );
            })}
          </Table>
        </Panel>
      </div>
      <Panel
        role="main"
        style={{
          gridColumn: "4 / span 6",
          gridRow: "1 / span 3"
        }}
      >
        <h2 className="sidebar_metrics__heading">
          {new Date().getFullYear()} Average Market Prices
        </h2>
        <MainAggregateFishTable
          headCells={[
            { label: "Species" },
            { label: "2020 Pounds Purchased" },
            { label: "2020 Average MP" },
            { label: "This Month Purchased" },
            { label: "This Month Average MP" }
          ]}
          aggregateFishDataBySpecies={aggregateFishDataBySpecies}
        />
      </Panel>
      <div
        className="catch_dashboard__sidebar"
        style={{
          gridColumn: "10 / span 3",
          gridRow: "1 / span 2"
        }}
      >
        <Panel>
          <h2 className="sidebar_metrics__heading">
            This Week's Fisher Leaderboard
          </h2>
          <h4>Total Pounds Reported</h4>
          {fisherLeaderboard &&
            fisherLeaderboard
              .slice(0, 5)
              .map(({ total_weight_lbs, placement }) => {
                const isFirstPlace = placement === 1;
                const placementIcon = isFirstPlace ? (
                  <Trophy
                    height={40}
                    width={40}
                    style={{ margin: "0 16px 0 0" }}
                  />
                ) : (
                  <span
                    style={{
                      flexGrow: 0,
                      flexShrink: 0,
                      flexBasis: 40,
                      fontSize: 16,
                      lineHeight: 1.1875,
                      margin: "0 16px 0 0",
                      width: 40,
                      fontWeight: 500
                    }}
                  >
                    {moment.localeData().ordinal(placement)}
                  </span>
                );
                const placementLabelFontSize = isFirstPlace ? 32 : 22;
                return (
                  <Panel
                    style={{ marginBottom: 8 }}
                    key={`${placement}-${total_weight_lbs}`}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "horizontal",
                        alignItems: "center"
                      }}
                    >
                      {placementIcon}
                      <span
                        style={{
                          fontFamily: "Roboto",
                          flexBasis: "auto",
                          fontWeight: 500,
                          fontSize: placementLabelFontSize,
                          lineHeight: 1.136,
                          whiteSpace: "nowrap"
                        }}
                      >
                        {total_weight_lbs.toLocaleString()} lbs
                      </span>
                    </div>
                  </Panel>
                );
              })}
        </Panel>
        <Panel style={{ marginTop: 16 }}>
          <h2 className="sidebar_metrics__heading">
            This Week's Vendor Leaderboard
          </h2>
          <h4>Total Pounds Purchased</h4>
          {vendorLeaderboard &&
            vendorLeaderboard
              .slice(0, 5)
              .map(({ total_weight_lbs, placement }) => {
                const isFirstPlace = placement === 1;
                const placementIcon = isFirstPlace ? (
                  <Trophy
                    height={40}
                    width={40}
                    style={{ margin: "0 16px 0 0" }}
                  />
                ) : (
                  <span
                    style={{
                      flexGrow: 0,
                      flexShrink: 0,
                      flexBasis: 40,
                      fontSize: 16,
                      lineHeight: 1.1875,
                      margin: "0 16px 0 0",
                      width: 40,
                      fontWeight: 500
                    }}
                  >
                    {moment.localeData().ordinal(placement)}
                  </span>
                );
                const placementLabelFontSize = isFirstPlace ? 32 : 22;
                return (
                  <Panel
                    style={{ marginBottom: 8 }}
                    key={`${placement}-${total_weight_lbs}`}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "horizontal",
                        alignItems: "center"
                      }}
                    >
                      {placementIcon}
                      <span
                        style={{
                          fontFamily: "Roboto",
                          flexBasis: "auto",
                          fontWeight: 500,
                          fontSize: placementLabelFontSize,
                          lineHeight: 1.136,
                          whiteSpace: "nowrap"
                        }}
                      >
                        {total_weight_lbs.toLocaleString()} lbs
                      </span>
                    </div>
                  </Panel>
                );
              })}
        </Panel>
      </div>
    </Page>
  );
};

MarketDashboard.defaultProps = {
  year: null,
  before: Date.now(),
  after: Date.now(),
  catchMethodCounts: new Map()
};

/**
 *
 * @param {*} state
 * @param {MarketDashboardProps} ownProps
 * @returns {MarketDashboardProps}
 */
function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    after: state.dashboard.timestamp_period[0],
    before: state.dashboard.timestamp_period[1],
    year: state.dashboard.timestamp_period[0].getFullYear(),
    metrics: state.dashboard.metrics
  };
}

export default connect(mapStateToProps)(MarketDashboard);
