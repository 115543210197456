import React from "react";
import "./App.css";
import { store } from "./reducers";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CatchDashboard from "./CatchDashboard";
import MarketDashboard from "./MarketDashboard";
import { Helmet } from "react-helmet";

import { Provider } from "react-redux";

function App() {
  return (
    <Provider store={store}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Community Dashboard</title>
      </Helmet>
      <Router>
        <Switch>
          <Route path="/market">
            <MarketDashboard />
          </Route>
          <Route path="/">
            <CatchDashboard />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
