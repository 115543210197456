import ApiService from "../../services/Api";
import { useState, useEffect } from "react";

import { generateTimestampPeriods } from "../../utils/generators";

/**
 * @param {Object} options
 * @param {number|Date} options.before
 * @param {number|Date} options.after
 */
export default function useHistoricalCatchData(options = {}) {
  const { start, end, size = "month" } = options;
  const service = new ApiService(process.env.REACT_APP_API_URL);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const periods = Array.from(generateTimestampPeriods(start, end, size));
      try {
        const results = await Promise.all(
          periods.map(async ([_start, end]) => {
            const data = (
              await service.getPlatformMetrics({
                after: start.valueOf(),
                before: end
              })
            ).reported_catches;
            return data;
          })
        );
        while (results.length < 12) {
          // Fill the rest of the year with default values
          results.push([
            {
              fishing_method: { type: "Bottomfishing" },
              species_catch_totals: [{ species: null, total_weight_lbs: null }],
              total_trips_reported: null,
              total_hours_reported: null,
              total_events_reported: null,
              total_active_fishers: null
            },
            {
              fishing_method: { type: "Spearfishing" },
              species_catch_totals: [{ species: null, total_weight_lbs: null }],
              total_trips_reported: null,
              total_hours_reported: null,
              total_events_reported: null,
              total_active_fishers: null
            },
            {
              fishing_method: { type: "Trolling" },
              species_catch_totals: [{ species: null, total_weight_lbs: null }],
              total_trips_reported: null,
              total_hours_reported: null,
              total_events_reported: null,
              total_active_fishers: null
            },
            {
              fishing_method: null,
              species_catch_totals: [{ species: null, total_weight_lbs: null }],
              total_trips_reported: null,
              total_hours_reported: null,
              total_events_reported: null,
              total_active_fishers: null
            }
          ]);
        }
        setData(results);
      } catch (err) {
        console.error("Error fetching historical catch data");
        setData(null);
      }
    }

    fetchData();
    return;
  }, [start, end, size]);

  return data;
}
