import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Fragment } from "react";

const Page = ({ children, title, ...props }) => (
  <Fragment>
    <header className="page__header">
      <span className="page__title">{title}</span>
      <nav className="navigation_header">
        <div className="page__content">
          <ul
            className="nav_links__list"
            style={{
              gridColumn: "1 / span 12"
            }}
          >
            <li className="nav_links__item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav_links__link"
              >
                Catch Dashboard
              </NavLink>
            </li>
            <li className="nav_links__item">
              <NavLink
                to="/market"
                activeClassName="active"
                className="nav_links__link"
              >
                Market Dashboard
              </NavLink>
            </li>
            <li className="nav_links__item">
              <a href="/admin" className="nav_links__link">
                Admin Login
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
    <div className="page__background">
      <div className="page__content" {...props}>
        {children}
      </div>
    </div>
  </Fragment>
);

Page.propTypes = {
  title: PropTypes.string
};

Page.defaultProps = {
  title: ""
};

export default Page;
