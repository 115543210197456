import { produce, enableMapSet } from "immer";

enableMapSet();

const currentYear = new Date().getFullYear();

const INITIAL_STATE = {
  timestamp_period: [new Date(currentYear, 0), new Date()],
  metrics: new Map([
    ["Total Pounds Caught", undefined],
    ["Total Pounds Caught by Bottomfishing", undefined],
    ["Total Pounds Caught by Trolling", undefined],
    ["Total Pounds Caught by Spearfishing", undefined],
    ["Fishing Trips Reported", undefined],
    ["Total Registered Users", undefined],
    ["Total Reporting Fishers", undefined],
    ["Total Registered Vendors", undefined],
    ["Total Reporting Vendors", undefined],
    ["Total Pounds Sold", undefined],
    ["Aggregate Fish Data By Species", []],
    ["Annual Catch Limits", []],
    ["Fisher Leaderboard", undefined],
    ["Vendor Leaderboard", undefined]
  ])
};

export const ACTION_TYPES = {
  SET_TIMESTAMP_PERIOD: "catch-dashboard/SET_TIMESTAMP_PERIOD",
  SET_METRIC: "catch-dashboard/SET_METRIC"
};

export const reducer = produce((state, action = { type: "" }) => {
  switch (action.type) {
    case ACTION_TYPES.SET_TIMESTAMP_PERIOD:
      state.timestamp_period[0] = action.value[0];
      state.timestamp_period[1] = action.value[1];
      break;
    case ACTION_TYPES.SET_METRIC:
      state.metrics.set(action.value.type, action.value.value);
      break;
    default:
  }
}, INITIAL_STATE);
