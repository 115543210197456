class ApiService {
  constructor(url) {
    this.url = `${url}`;
  }

  /**
   * Fetches all platform metrics, optionally between two timestamps.
   * @param {PlatformMetricsOptions} options
   */
  async getPlatformMetrics(options) {
    const url = new URL(`${this.url}/platform_metrics`);
    for (let [key, value] of Object.entries(options)) {
      url.searchParams.append(key, value);
    }
    const response = await fetch(url);
    const data = await response.json();

    return data;
  }
}

export default ApiService;
