import { combineReducers, createStore } from "redux";
import { reducer as dashboardReducer } from "./dashboard";

const rootReducer = combineReducers({
  dashboard: dashboardReducer
});

export default rootReducer;

export const store = createStore(rootReducer);
