import { useEffect, useState } from "react";

import ApiService from "../services/Api";
import { ACTION_TYPES } from "../reducers/dashboard";

/**
 * @param {string} metric
 * @param {number} value
 */
function updateMetric(metric, value) {
  if (typeof value === "undefined") {
    return;
  }
  return {
    type: ACTION_TYPES.SET_METRIC,
    value: {
      type: metric,
      value: value
    }
  };
}

/**
 *
 * @param {PlatformMetricsOptions} [options]
 */
function useMetrics(options = {}) {
  const service = new ApiService(process.env.REACT_APP_API_URL);
  const [data, setData] = useState(undefined);
  const { dispatch, ..._options } = options;

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await service.getPlatformMetrics(_options);
        setData(data);

        const {
          active_users,
          annual_catch_limits,
          reported_catches,
          reported_fishing_trips,
          registered_users,
          average_market_prices,
          catch_leaderboard,
          market_leaderboard,
          reported_vendor_purchases
        } = data;
        const actions = [];

        if (reported_catches) {
          for (let metric of reported_catches) {
            if (metric.fishing_method) {
              const aggregate =
                metric.species_catch_totals[
                  metric.species_catch_totals.length - 1
                ];
              actions.push(
                updateMetric(
                  `Total Pounds Caught by ${metric.fishing_method.type}`,
                  aggregate.total_weight_lbs
                ),
                updateMetric(
                  `Total Fishing Trips Reported by ${metric.fishing_method.type}`,
                  aggregate.total_trips_reported
                ),
                updateMetric(
                  `Total Hours Reported by ${metric.fishing_method.type}`,
                  aggregate.total_hours_reported
                ),
                updateMetric(
                  `Total Events Reported by ${metric.fishing_method.type}`,
                  aggregate.total_events_reported
                ),
                updateMetric(
                  `Total Active Fishers Reported by ${metric.fishing_method.type}`,
                  aggregate.total_active_fishers
                )
              );
            } else if (metric.fishing_method === null) {
              actions.push(
                updateMetric(
                  "Total Pounds Caught",
                  metric.species_catch_totals[
                    metric.species_catch_totals.length - 1
                  ].total_weight_lbs
                )
              );
            }
          }
        }
        if (reported_fishing_trips) {
          for (let metric of reported_fishing_trips) {
            switch (metric.status) {
              case "APPROVED":
                actions.push(
                  updateMetric("Fishing Trips Reported", metric.count)
                );
            }
          }
        }
        if (registered_users) {
          for (let metric of registered_users) {
            switch (metric.type) {
              case "DISTINCT":
                actions.push(
                  updateMetric("Total Registered Users", metric.count)
                );
                break;
              case "FISHER":
                actions.push(
                  updateMetric("Total Registered Fishers", metric.count)
                );
                break;
              case "VENDOR":
                actions.push(
                  updateMetric("Total Registered Vendors", metric.count)
                );
                break;
              default:
            }
          }
        }
        if (average_market_prices) {
          actions.push(
            updateMetric(
              "Total Pounds Sold",
              average_market_prices[average_market_prices.length - 1]
                .year_sold_lbs
            )
          );
          actions.push(
            updateMetric(
              "Aggregate Fish Data By Species",
              average_market_prices.filter(({ species, year_sold_lbs }) => {
                return species && year_sold_lbs;
              })
            )
          );
        }
        if (active_users) {
          for (let metric of active_users) {
            switch (metric.user_type) {
              case "FISHER":
                actions.push(
                  updateMetric("Total Reporting Fishers", metric.count)
                );
                break;
              case "VENDOR":
                actions.push(
                  updateMetric("Total Reporting Vendors", metric.count)
                );
                break;
              default:
            }
          }
        }
        if (catch_leaderboard) {
          actions.push(
            updateMetric("Fisher Leaderboard", catch_leaderboard.rankings)
          );
        }
        if (market_leaderboard) {
          actions.push(
            updateMetric("Vendor Leaderboard", market_leaderboard.rankings)
          );
        }
        if (annual_catch_limits) {
          const foundACLSet = new Set();
          actions.push(
            updateMetric(
              "Annual Catch Limits",
              annual_catch_limits.filter(acl => {
                if (!foundACLSet.has(acl.fishing_method.type)) {
                  foundACLSet.add(acl.fishing_method.type);
                  return true;
                }
                return false;
              })
            )
          );
        }
        if (reported_vendor_purchases) {
          for (let metric of reported_vendor_purchases) {
            switch (metric.status) {
              case "APPROVED":
                actions.push(
                  updateMetric("Total Sales Reported", metric.count)
                );
            }
          }
        }
        actions.filter(action => action).forEach(dispatch);
      } catch (err) {
        console.error(err);
        setData(null);
      }
    }

    fetchData();
  }, [options.before, options.after]);

  return data;
}

export default useMetrics;
