import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Progress } from "@sudokrew/wespac-components";
import Panel from "./Panel";

/**
 *
 * @param {number | null} number
 */
function formatNumberToString(number) {
  if (number == undefined) {
    return "-";
  }

  return number.toLocaleString();
}

const PROGRESS_SWATCHES = {
  Bottomfishing: ["#6DAB32", "#E0EECF", "#586947"],
  Trolling: ["#4195C6", "#D3E3E3", "#4C6371"],
  Spearfishing: ["#357473", "#D2E9F4", "#485A5A"]
};

const YTDReportedCatch = ({ current, goal, children, colorScale }) => {
  const label = children;

  return (
    <Progress max={goal} value={current} colorScale={colorScale}>
      {label}
    </Progress>
  );
};

/**
 * @typedef ReportedCatchesProps
 * @property {string} title
 * @property {any[]} data
 * @property {number | null} current
 * @property {number | null } goal
 *
 */

/**
 * @param {ReportedCatchesProps} props
 */
function ReportedCatches(props) {
  const {
    title,
    data,
    current,
    goal,
    disableBarGraph,
    reportData,
    ..._rest
  } = props;

  let total = current || 0;
  let max = goal;

  let currentLabel = `${formatNumberToString(total)} lbs`;
  let goalLabel = `${formatNumberToString(goal)} lbs`;
  let label = ``;

  return (
    <Panel className="ytd_reported_catches__component">
      <div style={{ gridColumn: "1 / span 9", gridRow: "1" }}>
        <span>{title}</span>
      </div>
      <dl
        style={{
          margin: 0,
          gridColumn: "1 / span 3",
          gridRow: "2"
        }}
      >
        {data.map((d, i) => {
          return (
            <div key={`metric-${i}`} style={{ display: "contents" }}>
              <dt>{d[0] && <strong>{d[0]}</strong>}</dt>
              <dd>{d[1]}</dd>
            </div>
          );
        })}
      </dl>
      <div className="ytd_reported_catches_bar_graph__container">
        <YTDReportedCatch
          current={total}
          goal={max}
          colorScale={PROGRESS_SWATCHES[title]}
        >
          {label}
        </YTDReportedCatch>
        <div className="ytd_reported_catches_bar_graph_bar_graph__left_label">
          <strong>{currentLabel}</strong>
          <br />
          Caught
        </div>
        <div className="ytd_reported_catches_bar_graph_bar_graph__right_label">
          <strong>{goalLabel}</strong>
          <br />
          Last year's total
        </div>
      </div>
    </Panel>
  );
}

ReportedCatches.defaultProps = {
  title: PropTypes.string,
  reportData: PropTypes.array,
  disableBarGraph: PropTypes.bool,
  current: PropTypes.number,
  goal: PropTypes.number
};

export default ReportedCatches;
