import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState } from "react";
import { Bar, Pie, Select } from "@sudokrew/wespac-components";
import { VictoryAxis, VictoryChart, VictoryTheme } from "victory";

import Page from "./components/Page";
import Panel from "./components/Panel";
import GraphContainer from "./components/Graphs/Container";
import Legend from "./components/Graphs/Legend";
import ReportedCatches from "./components/ReportedCatches";

import useMetrics from "./hooks/useMetrics";
import useHistoricalCatchData from "./hooks/useHistoricalCatchData";
import TotalReportedCatches from "./components/Graphs/TotalReportedCatches";

const FALLBACK_CATCH_TOTALS = {
  Bottomfishing:
    parseFloat(process.env.REACT_APP_FALLBACK_CATCH_TOTAL_BOTTOMFISHING) || 0,
  Trolling:
    parseFloat(process.env.REACT_APP_FALLBACK_CATCH_TOTAL_TROLLING) || 0,
  Spearfishing:
    parseFloat(process.env.REACT_APP_FALLBACK_CATCH_TOTAL_SPEARFISHING) || 0
};

const ALL_FISHING_METHOD = "All";
const BOTTOMFISHING_FISHING_METHOD = "Bottomfishing";
const TROLLING_FISHING_METHOD = "Trolling";
const SPEARFISHING_FISHING_METHOD = "Spearfishing";
const fishingMethodThemeColor = {
  Bottomfishing: "#56AE00",
  Spearfishing: "#0D7674",
  Trolling: "#0096CB"
};

/**
 * @param {number} number
 */
const toPercentage = number => {
  if (isNaN(number)) {
    return "";
  }

  return number.toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 0
  });
};

/**
 *
 * @param {number | null} number
 */
function formatNumberToString(number) {
  if (number == undefined) {
    return "-";
  }

  return number.toLocaleString();
}

export const SidebarMetricItem = props => {
  let { value, formatValue } = props;

  if (typeof formatValue === "function") {
    value = formatValue(value);
  }

  return (
    <div className="sidebar_metrics__container" style={props.style}>
      <strong className="sidebar_metrics__value">{value}</strong>
      <span className="sidebar_metrics__label">{props.label}</span>
    </div>
  );
};

SidebarMetricItem.propTypes = {
  value: PropTypes.number,
  formatValue: PropTypes.func,
  label: PropTypes.string
};

SidebarMetricItem.defaultProps = {
  value: null,
  label: ""
};

/**
 * @param {any[]} catches
 * @param {any[]} annualCatchLimits
 * @param {Object} options
 * @param {number|Date} options.before
 * @param {number|Date} options.after
 * @param {string} options.fishingMethod
 */
function renderCatchTotal(catches, annualCatchLimits, options) {
  const { before, after, fishingMethod } = options;
  return (
    <div
      id="catch_total"
      style={{
        gridColumn: "1 / span 6"
      }}
    >
      <span className="main_metrics__label">Year to Date—Catch Total</span>
      <TotalReportedCatches
        selectedFishingMethod={fishingMethod}
        data={{ catches, annualCatchLimits }}
        after={after}
        before={before}
      ></TotalReportedCatches>
    </div>
  );
}

/**
 *
 * @param {Object} options
 * @param {string} options.fishingMethod
 * @param {Object} options.catchCount
 * @param {Array} options.catchData
 * @param {Array} options.annualCatchLimitData
 * @param {number} options.catchCount.Bottomfishing
 * @param {number} options.catchCount.Spearfishing
 * @param {number} options.catchCount.Trolling
 *
 */
function renderOverallPoundsCaughtGraph(options) {
  const selectedAnnualCatchLimit = options.annualCatchLimitData.find(
    acl => acl.fishing_method.type === options.fishingMethod
  );
  const graphLabel = selectedAnnualCatchLimit
    ? "ACL Species Caught by Pound"
    : "Top Species Caught by Pound";
  let catchDataBySpeciesList = {
    Bottomfishing: new Map(),
    Spearfishing: new Map(),
    Trolling: new Map()
  };
  if (options.catchData) {
    options.catchData.forEach(monthlyCatchAggregates => {
      monthlyCatchAggregates.forEach(catchAggregate => {
        if (!catchAggregate.fishing_method) {
          return;
        }
        const currentCatchMethodType = catchAggregate.fishing_method.type;
        catchAggregate.species_catch_totals
          .slice(0, -1)
          .forEach(speciesCatchTotal => {
            const currentSpeciesName = speciesCatchTotal.species.common_name;
            if (
              selectedAnnualCatchLimit &&
              !speciesCatchTotal.species.acl_tracked
            ) {
              return;
            }
            if (
              !catchDataBySpeciesList[currentCatchMethodType].has(
                currentSpeciesName
              )
            ) {
              catchDataBySpeciesList[currentCatchMethodType].set(
                currentSpeciesName,
                0
              );
            }
            const currentTotal = catchDataBySpeciesList[
              currentCatchMethodType
            ].get(currentSpeciesName);

            catchDataBySpeciesList[currentCatchMethodType].set(
              currentSpeciesName,
              currentTotal + speciesCatchTotal.total_weight_lbs
            );
          });
      });
    });
  }

  let catchMethodSpeciesData = [];
  if (catchDataBySpeciesList[options.fishingMethod]) {
    catchMethodSpeciesData = Array.from(
      catchDataBySpeciesList[options.fishingMethod].entries()
    )
      .map(([commonName, totalWeightLbs]) => {
        return {
          x: commonName, // common_name
          y: totalWeightLbs // total_weight_lbs
        };
      })
      .sort((a, b) => a.y - b.y); // sort by total_weight_lbs descending
    if (!selectedAnnualCatchLimit) {
      catchMethodSpeciesData = catchMethodSpeciesData.slice(0, 8); // Limit top species list to 8 if no active ACL
    }
  }

  switch (options.fishingMethod) {
    case "All":
      return (
        <div
          id="overall_pounds_caught"
          style={{
            gridColumn: "7 / span 3"
          }}
        >
          <span className="main_metrics__label">
            Year to Date—Overall Caught Pounds
          </span>
          <Pie
            width={200}
            height={330}
            radius={100}
            containerComponent={<GraphContainer responsive={false} />}
            data={Object.keys(options.catchCount).map(c => {
              return {
                x: c,
                y: options.catchCount[c]
              };
            })}
            style={{
              data: {
                fill: ({ datum }) => {
                  switch (datum.x) {
                    case "Bottomfishing":
                      return fishingMethodThemeColor.Bottomfishing;
                    case "Spearfishing":
                      return fishingMethodThemeColor.Spearfishing;
                    case "Trolling":
                    default:
                      return fishingMethodThemeColor.Trolling;
                  }
                }
              }
            }}
          />
          <Legend
            containerComponent={
              <GraphContainer responsive={false} width={161} height={81} />
            }
            orientation="vertical"
            rowGutter={-5}
            data={[
              {
                name: `${toPercentage(
                  options.catchCount["Spearfishing"]
                )} Spearfishing`,
                symbol: { fill: "#0D7674", type: "square" }
              },
              {
                name: `${toPercentage(
                  options.catchCount["Trolling"]
                )} Trolling`,
                symbol: { fill: "#0096CB", type: "square" }
              },
              {
                name: `${toPercentage(
                  options.catchCount["Bottomfishing"]
                )} Bottomfishing`,
                symbol: { fill: "#56AE00", type: "square" }
              }
            ]}
          />
        </div>
      );
    default:
      return (
        <div
          style={{
            gridColumn: "7 / span 3"
          }}
        >
          <span className="main_metrics__label">{graphLabel}</span>
          <VictoryChart
            animate={{ duration: 300 }}
            domainPadding={{ x: 16.5, y: [0, 0] }}
            height={330}
            width={270}
            padding={{ top: 20, right: 20, bottom: 20, left: 130 }}
          >
            <VictoryAxis
              dependentAxis
              style={{
                grid: { stroke: "grey" },
                tickLabels: { fontWeight: 100, fontSize: 11, padding: 7 }
              }}
              tickCount={4}
            />
            <VictoryAxis
              style={{
                tickLabels: {
                  fontWeight: 100,
                  fontSize: 13,
                  lineHeight: 29,
                  padding: 11
                }
              }}
            />
            <Bar
              barWidth={20}
              horizontal
              style={{
                data: { fill: fishingMethodThemeColor[options.fishingMethod] }
              }}
              data={catchMethodSpeciesData}
            />
          </VictoryChart>
        </div>
      );
  }
}

/**
 * @typedef CatchMethodCount
 * @property {string} type
 * @property {string} totalPoundMass
 */

/**
 * @typedef CatchDashboardProps
 * @property {number} year
 * @property {number} after
 * @property {number} before
 * @property {CatchMethodCount[]} metrics
 */

/**
 * @param {CatchDashboardProps} props
 */
const CatchDashboard = props => {
  useMetrics({
    after: props.after.valueOf(),
    before: props.before.valueOf(),
    dispatch: props.dispatch
  });
  const { metrics } = props;

  const catchData = useHistoricalCatchData({
    end: props.before,
    start: props.after
  });

  const totalCatchCount = metrics.get("Total Pounds Caught");
  const bottomfishingCatchCount = metrics.get(
    "Total Pounds Caught by Bottomfishing"
  );
  const trollingCatchCount = metrics.get("Total Pounds Caught by Trolling");
  const spearfishingCatchCount = metrics.get(
    "Total Pounds Caught by Spearfishing"
  );

  const catchTotalPercentages = {
    Bottomfishing: bottomfishingCatchCount / totalCatchCount,
    Spearfishing: spearfishingCatchCount / totalCatchCount,
    Trolling: trollingCatchCount / totalCatchCount
  };
  const fishingTripsReported = metrics.get("Fishing Trips Reported");
  const totalRegisteredFishers = metrics.get("Total Registered Fishers");
  const totalReportingFishers = metrics.get("Total Reporting Fishers");

  const [selectedFishingMethod, selectFishingMethod] = useState("All");

  const annualCatchLimits = metrics.get("Annual Catch Limits");
  const selectedAnnualCatchLimit = annualCatchLimits.find(
    acl => acl.fishing_method.type === selectedFishingMethod
  );

  const lastYearsMetrics = useHistoricalCatchData({
    // after: moment(props.after.valueOf()).subtract(1, "year").valueOf(),
    // before: props.after.valueOf()
    start: props.after,
    end: props.before
  });

  const endingYearMetrics = lastYearsMetrics[lastYearsMetrics.length - 1];

  return (
    <Page
      title="Dashboard"
      id="catch_dashboard"
      style={{
        gridTemplateRows: "min-content min-content 1fr"
      }}
    >
      <Panel
        className="catch_dashboard__sidebar"
        style={{
          gridColumn: "1 / span 3",
          gridRow: "1 / span 2"
        }}
      >
        <SidebarMetricItem
          value={totalCatchCount}
          formatValue={formatNumberToString}
          label={`Total Pounds Caught for ${props.year}`}
        />
        <hr />
        <h2 className="sidebar_metrics__heading">Total Pounds Caught By</h2>
        <SidebarMetricItem
          value={bottomfishingCatchCount}
          formatValue={formatNumberToString}
          label={`Bottomfishing`}
        />
        <SidebarMetricItem
          value={trollingCatchCount}
          formatValue={formatNumberToString}
          label={`Trolling`}
        />
        <SidebarMetricItem
          value={spearfishingCatchCount}
          formatValue={formatNumberToString}
          label={`Spearfishing`}
        />
        <hr />
        <SidebarMetricItem
          value={fishingTripsReported}
          formatValue={formatNumberToString}
          label={`Fishing Trips Reported`}
        />
        <SidebarMetricItem
          value={totalRegisteredFishers}
          formatValue={formatNumberToString}
          label={`Total Registered Fishers`}
        />
        <SidebarMetricItem
          value={totalReportingFishers}
          formatValue={formatNumberToString}
          label={`Total Reporting Fishers`}
        />
      </Panel>
      <Panel
        role="main"
        style={{
          gridColumn: "4 / span 9",
          gridRow: "1 / span 1"
        }}
      >
        <div
          style={{
            borderBottom: "1px solid #D8D8D8",
            gridColumn: "1 / span 9"
          }}
        >
          <Select
            style={{
              margin: "0 0 12px"
            }}
            value={selectedFishingMethod}
            onChange={event => {
              selectFishingMethod(event.target.value);
            }}
          >
            <option value={ALL_FISHING_METHOD}>All</option>
            <option value={BOTTOMFISHING_FISHING_METHOD}>Bottomfishing</option>
            <option value={SPEARFISHING_FISHING_METHOD}>Spearfishing</option>
            <option value={TROLLING_FISHING_METHOD}>Trolling</option>
          </Select>
        </div>

        {renderCatchTotal(catchData, annualCatchLimits, {
          before: props.before,
          after: props.after,
          fishingMethod: selectedFishingMethod
        })}

        {renderOverallPoundsCaughtGraph({
          fishingMethod: selectedFishingMethod,
          catchCount: catchTotalPercentages,
          catchData,
          annualCatchLimitData: annualCatchLimits
        })}
      </Panel>
      {renderReportedCatchesByFishingMethod(selectedFishingMethod, {
        title: selectedFishingMethod,
        lastYearsMetrics: endingYearMetrics,
        data: metrics
      })}
    </Page>
  );
};

/**
 *
 * @param {string} fishingMethod
 * @param {object} report
 * @param {string} report.title
 * @param {number|null} report.progress.annualCatchLimit
 * @param {number|null} report.progress.totalCaught
 * @param {any[]} report.datadata
 */
function renderReportedCatchesByFishingMethod(
  fishingMethod,
  { title, data, lastYearsMetrics = [] }
) {
  const selectedFishingMethod = fishingMethod;
  if (selectedFishingMethod === "All") {
    lastYearsMetrics = lastYearsMetrics.filter(metric => {
      return metric.fishing_method;
    });
  } else {
    lastYearsMetrics = lastYearsMetrics.filter(metric => {
      return (
        metric.fishing_method && metric.fishing_method.type === fishingMethod
      );
    });
  }

  return lastYearsMetrics.map(lastYearsMetric => {
    const fishingMethod = lastYearsMetric.fishing_method;
    let lastYearsTotalWeight =
      lastYearsMetric.species_catch_totals[
        lastYearsMetric.species_catch_totals.length - 1
      ].total_weight_lbs;

    if (!lastYearsTotalWeight) {
      lastYearsTotalWeight = FALLBACK_CATCH_TOTALS[fishingMethod.type];
    }

    let selectedPoundsCaught = null;
    let selectedTripsReported = null;
    let selectedHoursReported = null;
    let selectedEventsReported = null;
    let selectedActiveFishersReported = null;

    // Selected fishing method metrics
    selectedPoundsCaught = data.get(
      `Total Pounds Caught by ${fishingMethod.type}`
    );
    selectedTripsReported = data.get(
      `Total Fishing Trips Reported by ${fishingMethod.type}`
    );
    selectedHoursReported = data.get(
      `Total Hours Reported by ${fishingMethod.type}`
    );
    selectedEventsReported = data.get(
      `Total Events Reported by ${fishingMethod.type}`
    );
    selectedActiveFishersReported = data.get(
      `Total Active Fishers Reported by ${fishingMethod.type}`
    );

    let current = selectedPoundsCaught;
    let goal = lastYearsTotalWeight;

    return (
      <ReportedCatches
        key={`${selectedFishingMethod}_${fishingMethod.type}`}
        title={fishingMethod.type}
        data={[
          [formatNumberToString(selectedTripsReported), "Trips Reported"],
          [formatNumberToString(selectedHoursReported), "Hours Reported"],
          [
            formatNumberToString(selectedEventsReported),
            "Fishing Events Reported"
          ],
          [
            formatNumberToString(selectedActiveFishersReported),
            "Active Fishers"
          ]
        ]}
        current={current}
        goal={goal}
      />
    );
  });
}

CatchDashboard.defaultProps = {
  year: null,
  before: Date.now(),
  after: Date.now(),
  catchMethodCounts: new Map()
};

/**
 *
 * @param {*} state
 * @param {CatchDashboardProps} ownProps
 * @returns {CatchDashboardProps}
 */
function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    after: state.dashboard.timestamp_period[0],
    before: state.dashboard.timestamp_period[1],
    year: state.dashboard.timestamp_period[0].getFullYear(),
    metrics: state.dashboard.metrics
  };
}

export default connect(mapStateToProps)(CatchDashboard);
