import React from "react";
import PropTypes from "prop-types";
import {
  VictoryChart,
  VictoryStack,
  VictoryAxis,
  VictoryLine,
  VictoryLabel,
  VictoryLegend as Legend,
  VictoryContainer as GraphContainer
} from "victory";
import { Area, Scatter, Line } from "@sudokrew/wespac-components";

const COLOR_SWATCHES = {
  Bottomfishing: "#88C74C",
  Spearfishing: "#609F9E",
  Trolling: "#6DB6DB",
  TotalReportedCatches: "#133BAF"
};

const MONTH_LABELS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

/**
 *
 * @param {number} index
 */
function indexToMonthLabel(index) {
  return MONTH_LABELS[index];
}

const TotalReportedCatches = props => {
  const { selectedFishingMethod, data } = props;
  const { catches, annualCatchLimits } = data;
  let latestMonthIndex = new Date().getMonth();
  if (
    !catches ||
    catches.length === 0 ||
    catches.every(
      monthAggregates => monthAggregates && monthAggregates.length === 0
    )
  ) {
    return (
      <VictoryChart
        padding={{ top: 25, left: 85, bottom: 50, right: 10 }}
        domainPadding={{ x: 0, y: [0, 50] }}
      >
        <VictoryAxis
          dependentAxis
          label="Year to Date Total Pounds Caught"
          style={{
            axisLabel: {
              padding: 65,
              fontFamily: "Roboto",
              fontSize: 13,
              fontWeight: 100
            },
            tickLabels: {
              fontFamily: "Roboto",
              fontSize: 12,
              fontWeight: 100
            }
          }}
          tickFormat={t => t.toLocaleString()}
        />
        <VictoryAxis
          style={{
            grid: {
              stroke: "#2A2A2A",
              strokeWidth: ({ index }) => {
                return index === latestMonthIndex ? 1 : 0;
              }
            },
            tickLabels: {
              fontFamily: "Roboto",
              fontSize: 12,
              fontWeight: 100,
              padding: 10
            }
          }}
        />
        <VictoryAxis tickValues={MONTH_LABELS} />
      </VictoryChart>
    );
  }
  const selectedAnnualCatchLimit = annualCatchLimits.find(acl => {
    return acl.fishing_method.type === selectedFishingMethod;
  });
  const bottomfishingData = catches.map((monthAggregates, index) => {
    if (!monthAggregates) {
      return null;
    }
    const methodAggregate = monthAggregates.find(methodAggregate => {
      return methodAggregate.fishing_method.type === "Bottomfishing";
    });
    if (!methodAggregate) {
      return null;
    }
    return {
      x: indexToMonthLabel(index),
      y:
        methodAggregate.species_catch_totals[
          methodAggregate.species_catch_totals.length - 1
        ].total_weight_lbs
    };
  });
  const spearfishingData = catches.map((monthAggregates, index) => {
    if (!monthAggregates) {
      return null;
    }
    const methodAggregate = monthAggregates.find(methodAggregate => {
      return methodAggregate.fishing_method.type === "Spearfishing";
    });
    if (!methodAggregate) {
      return null;
    }
    return {
      x: indexToMonthLabel(index),
      y:
        methodAggregate.species_catch_totals[
          methodAggregate.species_catch_totals.length - 1
        ].total_weight_lbs
    };
  });
  const trollingData = catches.map((monthAggregates, index) => {
    if (!monthAggregates) {
      return null;
    }
    const methodAggregate = monthAggregates.find(methodAggregate => {
      return methodAggregate.fishing_method.type === "Trolling";
    });
    if (!methodAggregate) {
      return null;
    }
    return {
      x: indexToMonthLabel(index),
      y:
        methodAggregate.species_catch_totals[
          methodAggregate.species_catch_totals.length - 1
        ].total_weight_lbs
    };
  });

  let targetDataSet = null;
  switch (selectedFishingMethod) {
    case "Bottomfishing":
      targetDataSet = bottomfishingData;
      break;
    case "Spearfishing":
      targetDataSet = spearfishingData;
      break;
    case "Trolling":
      targetDataSet = trollingData;
      break;
    default:
  }
  if (targetDataSet) {
    // Should render a single area graph if a selected fishing method is provided.
    let catchLimit = selectedAnnualCatchLimit
      ? selectedAnnualCatchLimit.total_weight_lbs
      : null;
    const legendData = [
      { name: selectedFishingMethod, symbol: { type: "square" } }
    ];
    const totalTargetData = Math.max(
      ...targetDataSet.map(d => {
        return d.y || 0;
      })
    );
    if (catchLimit) {
      legendData.push({
        name: `${new Date().getFullYear()} ACL`,
        symbol: { type: "minus" }
      });
    }
    return (
      <VictoryChart
        padding={{ top: 25, left: 85, bottom: 50, right: 10 }}
        domainPadding={{ x: 0, y: [0, 50] }}
      >
        <Area
          style={{ data: { fill: COLOR_SWATCHES[selectedFishingMethod] } }}
          data={targetDataSet}
        ></Area>
        <VictoryAxis
          dependentAxis
          label="Year to Date Total Pounds Caught"
          style={{
            axisLabel: {
              padding: 65,
              fontFamily: "Roboto",
              fontSize: 13,
              fontWeight: 100
            },
            tickLabels: {
              fontFamily: "Roboto",
              fontSize: 12,
              fontWeight: 100
            }
          }}
          tickFormat={t => t.toLocaleString()}
        />
        <VictoryAxis
          style={{
            grid: {
              stroke: "#2A2A2A",
              strokeWidth: ({ index }) => {
                return index === latestMonthIndex ? 1 : 0;
              }
            },
            tickLabels: {
              fontFamily: "Roboto",
              fontSize: 12,
              fontWeight: 100,
              padding: 10
            }
          }}
        />
        {catchLimit != undefined && (
          <VictoryLine
            style={{
              data: {
                stroke: COLOR_SWATCHES[selectedFishingMethod],
                strokeWidth: 2
              },
              labels: {
                fontSize: 12,
                fontWeight: 100,
                textAnchor: "end"
              }
            }}
            labelComponent={<VictoryLabel renderInPortal dy={-5} />}
            data={[
              { x: 1, y: catchLimit },
              { x: 2, y: catchLimit },
              { x: 3, y: catchLimit },
              { x: 4, y: catchLimit },
              { x: 5, y: catchLimit },
              { x: 6, y: catchLimit },
              { x: 7, y: catchLimit },
              { x: 8, y: catchLimit },
              { x: 9, y: catchLimit },
              { x: 10, y: catchLimit },
              { x: 11, y: catchLimit },
              { x: 12, y: catchLimit }
            ]}
            labels={({ data, index }) => {
              if (index == data.length - 1) {
                return `${((totalTargetData / catchLimit) * 100).toFixed(
                  2
                )}% of ACL met`;
              }
            }}
          />
        )}
        <Legend
          x={70}
          y={280}
          gutter={4}
          orientation="horizontal"
          style={{ labels: { fontSize: 10 } }}
          colorScale={[`${COLOR_SWATCHES[selectedFishingMethod]}`]}
          data={legendData}
        />
      </VictoryChart>
    );
  }

  const totalReportedCatches = catches.map((monthAggregates, index) => {
    if (!monthAggregates) {
      return null;
    }
    const totalAggregate = monthAggregates.find(
      aggregate => aggregate.fishing_method === null
    );
    if (!totalAggregate) {
      return null;
    }
    return {
      x: indexToMonthLabel(index),
      y: totalAggregate.species_catch_totals[0].total_weight_lbs
    };
  });

  return (
    <>
      <VictoryChart
        padding={{ top: 25, left: 85, bottom: 50, right: 10 }}
        domainPadding={{ x: 0, y: [0, 50] }}
      >
        <VictoryAxis
          dependentAxis
          label="Year to Date Total Pounds Caught"
          style={{
            axisLabel: {
              padding: 65,
              fontFamily: "Roboto",
              fontSize: 13,
              fontWeight: 100
            },
            tickLabels: {
              fontFamily: "Roboto",
              fontSize: 12,
              fontWeight: 100
            }
          }}
          tickFormat={t => t.toLocaleString()}
        />
        <VictoryAxis
          style={{
            grid: {
              stroke: "#2A2A2A",
              strokeWidth: ({ index }) => {
                return index === latestMonthIndex ? 1 : 0;
              }
            },
            tickLabels: {
              fontFamily: "Roboto",
              fontSize: 12,
              fontWeight: 100,
              padding: 10
            }
          }}
        />
        <VictoryStack>
          <Area
            style={{ data: { fill: COLOR_SWATCHES["Bottomfishing"] } }}
            data={bottomfishingData}
          ></Area>
          <Area
            style={{ data: { fill: COLOR_SWATCHES["Spearfishing"] } }}
            data={spearfishingData}
          ></Area>
          <Area
            style={{ data: { fill: COLOR_SWATCHES["Trolling"] } }}
            data={trollingData}
          ></Area>
        </VictoryStack>

        <Scatter
          size={2}
          data={totalReportedCatches}
          style={{ data: { fill: "#133BAF" } }}
        ></Scatter>

        <Line
          data={totalReportedCatches}
          style={{ data: { stroke: "#133BAF", strokeWidth: "1px" } }}
        ></Line>
        <Legend
          x={70}
          y={280}
          gutter={4}
          orientation="horizontal"
          style={{ labels: { fontSize: 10 } }}
          colorScale={[
            `${COLOR_SWATCHES.TotalReportedCatches}`,
            `${COLOR_SWATCHES.Bottomfishing}`,
            `${COLOR_SWATCHES.Spearfishing}`,
            `${COLOR_SWATCHES.Trolling}`
          ]}
          data={[
            { name: "Total Reported Catches", symbol: { type: "minus" } },
            { name: "Bottomfishing", symbol: { type: "square" } },
            { name: "Spearfishing", symbol: { type: "square" } },
            { name: "Trolling", symbol: { type: "square" } }
          ]}
        />
      </VictoryChart>
    </>
  );
};

TotalReportedCatches.propTypes = {
  selectedFishingMethod: PropTypes.string
};

TotalReportedCatches.defaultProps = {
  selectedFishingMethod: null,
  data: null,
  before: (new Date().getFullYear() + 1, 0),
  after: (new Date().getFullYear(), 0)
};

export default TotalReportedCatches;
